<template>
  <div class="adaptation-project">
    <div v-if="hasBindEntity">
      <div class="condition">
        <div>
          状态：
          <el-radio-group
            v-model="radio"
            size="small"
            @change="handleStatus"
            text-color="#409EFF"
          >
            <el-radio-button label="0">不限</el-radio-button>
            <el-radio-button label="1">申报中</el-radio-button>
            <el-radio-button label="2">已截止</el-radio-button>
          </el-radio-group>
        </div>
        <div>
          排序：
          <el-radio-group
            v-model="radio2"
            size="small"
            @change="handleSort"
            text-color="#409EFF"
          >
            <el-radio-button label="0">按匹配度</el-radio-button>
            <el-radio-button label="1">按最新</el-radio-button>
            <el-radio-button label="2">按截止日期</el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <!-- 数据加载框 -->
      <div class="loading"
      v-show="loading"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 0.7)">
      </div>
      <div class="content" v-if="!loading && list.length>0">
        <ul>
          <li
            v-for="(item, i) in list"
            :key="i"
            @click="
              goToDetail(
                item.project.zhengceid,
                item.project.id,
                item.sysFollowPolicyId
              )
            "
            class="li_card"
          >
            <div v-show="!item.state" class="unread"></div>
            <div class="project_name">
              {{ item.project.subtitle }}
            </div>
            <div style="height: 24px" v-if="!item.mappingname"></div>
            <ul v-if="item.mappingname">
              <li
                v-for="item2 in item.mappingname"
                :key="item2"
                :class="{
                  fundSupport: item2 == '资金扶持',
                  equityInvestment: item2 == '股权投资',
                  titleRecognition: item2 == '称号认定',
                  taxAndFeeReductions: item2 == '减税降费',
                  financialSupport: item2 == '金融扶持',
                  others: item2 == '其他',
                }"
              >
                {{ item2 }}
              </li>
              <li
                v-if="item.mappingname[0] == '资金扶持' && item.project.money > 0"
                style="border: 1px solid rgb(229, 229, 229);color: rgb(141, 141, 141);"
              >
                {{ item.project.money }}万元
              </li>
            </ul>
            <div>
              <span>{{ item.project.fwDepartment }}</span>
              <span
                :class="{ red: parseInt(item.time) <= 30 }"
                v-if="parseInt(item.time) > 0"
                >剩{{ parseInt(item.time) }}天</span
              >
              <span
                :class="{ red: parseInt(item.time) <= 30 }"
                v-else-if="parseInt(item.time) === 0"
                >今天截止</span
              >
              <span style="color: #8d8d8d" v-else>已截止</span>
            </div>
          </li>
        </ul>
        <el-pagination
          v-show="total > 0"
          background
          layout="prev, pager, next"
          :page-size="pagesize"
          :total="total"
          @prev-click="prevClick"
          @next-click="nextClick"
          @current-change="currentChange"
          :current-page="page"
        >
        </el-pagination>
      </div>
      <div v-if="!loading && list.length <= 0">
        <el-empty description="暂无内容"></el-empty>
      </div>
    </div>

    <div v-else style="margin-bottom: 50px">
      <div class="hasBindEntity">
        <div>
          <img src="../../../assets/pc/images/push/subject.png" alt="" />
        </div>
        <div style="margin-top: 10px" class="information_color">
          请先绑定主体
        </div>
        <div style="margin: 10px 0" class="information_color">
          以便查询更多政策资讯
        </div>
        <div class="button" style="text-align: center; margin-top: 30px">
          <button class="goto-bind" @click="dialogVisible = true">
            立即绑定
          </button>
        </div>
      </div>
    </div>
    <el-dialog
      title="绑定主体"
      :visible.sync="dialogVisible"
      width="500px"
    >
      <el-form ref="form" :model="form" class="form" :rules="rule" label-position="top">
        <el-form-item prop="name" label="主体名称">
          <el-autocomplete
                class="inline-input"
                v-model="form.name"
                :fetch-suggestions="querySearch"
                placeholder="请输入机构执照名称"
                :trigger-on-focus="false"
                @select="handleSelect"
                :debounce="0"
                style="width:100%"
                clearable
              >
            </el-autocomplete>
        </el-form-item>
        <el-form-item :label="form.label" v-if="form.type == '0'">
          <div class="creditCode">
            <div style="flex:1">{{form.code}}</div>
            <div class="div-code">
              <el-input v-model="form.first_code" maxlength="1" ref="inputRef1"></el-input>
            </div>
            <div class="div-code">
              <el-input v-model="form.second_code"  maxlength="1" ref="inputRef2"></el-input>
            </div>
            <div class="div-code">
              <el-input v-model="form.third_code"  maxlength="1" ref="inputRef3"></el-input>
            </div>
            <div class="div-code">
              <el-input v-model="form.fourth_code"  maxlength="1" ref="inputRef4"></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item :label="form.label" v-else>
          <el-input v-model="form.codeInput" placeholder="输入18位统一社会信用代码" maxlength="18"></el-input>
        </el-form-item>
      </el-form>
      <div class="bind-btn-box">
        <button class="bind-btn" style="width:280px" @click="bindEntity">立即绑定</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { request } from '../../../network';
import { getMemberId, getEntityId } from '@/assets/public/utils/token';
export default {
  data () {
    return {
      radio: '0',
      radio2: '0',
      page: 1,
      pagesize: 9,
      total: 100,
      list: [],
      whichpath: 'push',
      hasBindEntity: true,
      dialogVisible: false,
      form: {
        name: '',
        code: '',
        first_code: '',
        second_code: '',
        third_code: '',
        fourth_code: '',
        label: '统一社会信用代码',
        type: '1',
        codeInput: '',
        creditCode: '',
        area: '',
        estiblishTime: '',
        regCapital: '',
        legalPersonName: ''
      },
      rule: {
        name: [
          { required: true, message: '请输入机构执照全称', trigger: 'blur' }
        ]
      },
      restaurants: [],
      loading: false
    };
  },
  created () {
    this.getAdaptationProject(this.radio, this.radio2);
    this.getAccountInformation();
  },
  mounted () {},
  watch: {
    'form.name' (val) {
      if (!val) {
        this.form.code = '';
        this.form.type = '1';
        this.form.label = '统一社会信用代码';
      }
    },
    'form.first_code' (val) {
      if (val !== '') {
        this.$refs[`inputRef${2}`].focus();
      }
    },
    'form.second_code' (val) {
      if (val !== '') {
        this.$refs[`inputRef${3}`].focus();
      } else {
        this.$refs[`inputRef${1}`].focus();
      }
    },
    'form.third_code' (val) {
      if (val !== '') {
        this.$refs[`inputRef${4}`].focus();
      } else {
        this.$refs[`inputRef${2}`].focus();
      }
    },
    'form.fourth_code' (val) {
      if (val === '') {
        this.$refs[`inputRef${3}`].focus();
      }
    }
  },
  computed: {},
  // inject:["reload"],
  methods: {
    async getAccountInformation () {
      if (getMemberId() !== '') {
        const { data: res } = await request({
          method: 'GET',
          url: '/pcp/PersonalCenter/accountInformation',
          params: {
            id: getMemberId()
          }
        });
        if (res.code !== 200) return this.$message.error('信息获取失败');
        this.hasBindEntity = !!res.data[0].name;
        return Promise.resolve();
      }
    },
    async getAdaptationProject (status, sort) {
      this.loading = true;
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/intelligentPush/getProjectByPolicy',
        params: {
          memberId: getMemberId(),
          status: status,
          sort: sort,
          pageNo: this.page,
          pageSize: this.pagesize
        }
      });
      if (res.code !== 200) return this.$message.error('信息获取失败');
      if (res.data.list) {
        res.data.list.map((item) => {
          if (new Date() > new Date(item.project.declareDate)) {
            item.time = -1;
          } else if (new Date() === new Date(item.project.declareDate)) {
            item.time = 0;
          } else {
            const data = new Date(item.project.declareDate) - new Date();
            item.time = data / (24 * 3600 * 1000) + 1;
          }
        });
        this.total = res.data.count;
        this.list = res.data.list;
      } else {
        this.total = 0;
        this.list = [];
      }
      this.loading = false;
      // this.reload();
    },
    goToDetail (id, inputId, sysFollowPolicyId) {
      // this.changeStatus(sysFollowPolicyId);
      this.$router.push({
        path: '/formaldetails/jiedu',
        query: {
          id: id,
          inputId: inputId,
          whichpath: this.whichpath
        }
      });
    },
    async changeStatus (id) {
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/intelligentPush/updateRead',
        params: { sysFollowPolicyId: id }
      });
      if (res.code === 200 && res.data) this.getAdaptationProject();
    },
    handleStatus (data) {
      this.radio = data;
      this.page = 1;
      this.getAdaptationProject(data, this.radio2);
    },
    handleSort (data) {
      this.page = 1;
      this.radio2 = data;
      this.getAdaptationProject(this.radio, data);
    },
    prevClick () {
      this.page--;
      this.getAdaptationProject(this.radio, this.radio2);
    },
    nextClick () {
      this.page++;
      this.getAdaptationProject(this.radio, this.radio2);
    },
    currentChange (val) {
      this.page = val;
      this.getAdaptationProject(this.radio, this.radio2);
    },
    mouseOver (e) {
      e.target.style.background = 'background:red';
    },
    mouseLeave (e) {
      e.target.style.background = '';
    },
    getEntity () {
      return getEntityId();
    },
    testCode (rule, value, callback) {
      if (value.length !== 18) {
        callback(new Error('输入18位统一社会信用代码'));
      } else {
        callback();
      }
    },
    async bindMember (area, estiblishTime, regCapital, legalPersonName, entityName, code) {
      const { data: res } = await request({
        method: 'post',
        url: '/pcp/PersonalCenter/newbindmemberone',
        data: {
          area: area,
          estiblishTime: estiblishTime,
          regCapital: regCapital,
          legalPersonName: legalPersonName,
          memberId: getMemberId(),
          entityName: entityName,
          code: code,
          sysType: 1
        }
      });
      if (res.code !== 200) { return this.$message.error('主体名称或信用代码不正确'); }
      // this.hasBindEntity = true
      this.dialogVisible = !this.dialogVisible;
      this.$message.success('绑定成功');
      const str = JSON.parse(localStorage.getItem('userinfo'));
      str.entityId = res.data.entityId;
      const str2 = JSON.stringify(str);
      localStorage.setItem('userinfo', str2);
      sessionStorage.setItem('userinfo', str2);
      this.getAdaptationProject(0, 0);
      this.getAccountInformation();
    },
    bindEntity () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.form.type === '0') {
            if (this.form.first_code === this.form.creditCode[14] && this.form.second_code === this.form.creditCode[15] && this.form.third_code === this.form.creditCode[16] && this.form.fourth_code === this.form.creditCode[17]) {
              this.bindMember(this.form.area, this.form.estiblishTime, this.form.regCapital, this.form.legalPersonName, this.form.name, this.form.creditCode);
            } else {
              this.$message.error('请补全后四位');
            }
          } else {
            if (this.form.type === '1' && this.form.codeInput && this.form.codeInput.length === 18) {
              this.bindMember(this.form.area, this.form.estiblishTime, this.form.regCapital, this.form.legalPersonName, this.form.name, this.form.creditCode);
            } else {
              this.$message.error('请输入18位社会信用代码');
            }
          }
        }
      });
    },
    querySearch (queryString, cb) {
      request({
        method: 'GET',
        url: '/pcp/PersonalCenter/getentitynameandcode',
        params: {
          entityName: queryString
        }
      }).then(res => {
        if (res.data.code === 200) {
          res.data.data.items.forEach(v => {
            v.value = v.name;
          });
          let results = [];
          res.data.data.items.map(item => {
            // results.push(Object.assign({},{value:item.name}))
            results.push(item);
          });
          if (results.length === 0) {
            results = [ { value: '暂无数据' } ];
          }
          cb(results);
        }
      }).catch(err => { console.log(err); });
    },
    handleSelect (item) {
      this.form.code = item.creditCode.slice(0, 6) + '******';
      this.form.label = '请补全统一社会信用代码';
      this.form.type = '0';
      this.form.creditCode = item.creditCode;
      this.form.area = item.base;
      this.form.estiblishTime = item.estiblishTime;
      this.form.regCapital = item.regCapital;
      this.form.legalPersonName = item.legalPersonName;
      this.form.name = item.name;
    }
  }
};
</script>

<style lang="less" scoped>
.adaptation-project {
  .condition {
    display: flex;
    font-size: 14px;
    margin-bottom: 30px;
    & > div {
      margin-right: 50px;
      /deep/.el-radio-button__orig-radio:checked + .el-radio-button__inner {
        background-color: #fff;
      }
    }
  }
  .content {
    & > ul {
      display: flex;
      flex-wrap: wrap;
      & > li {
        position: relative;
        width: 350px;
        height: 160px;
        margin-right: 75px;
        margin-bottom: 20px;
        border-top: 1px solid #cccccc;
        border-right: 1px solid #cccccc;
        // border-bottom: 1px solid #CCCCCC;
        border-left: 1px solid #cccccc;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 23px 16px 21px 17px;
        &:nth-child(3n){
          margin-right: 0;
        }
        .unread {
          width: 8px;
          height: 8px;
          background-color: red;
          border-radius: 50%;
          position: absolute;
          right: 4px;
          top: 4px;
        }
        .project_name{
          height: 38px;
          font-size: 16px; 
          color: #000000;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp:2;
          -webkit-box-orient: vertical;
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
        & > div:first-child {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        & > ul {
          display: flex;
          margin-top: 16px;
          white-space: nowrap;
          overflow: hidden;
          li {
            height: 24px;
            line-height: 24px;
            padding: 0 6px;
            font-size: 12px;
            border-radius: 3px;
            margin-left: 10px;
            border: 1px solid #e5e5e5;
            background-color: #fff;
            color: #8d8d8d;
            box-sizing: border-box;
            &:first-child {
              margin-left: 0;
            }
            &.fundSupport {
              border: none;
              background-color: #f5831d;
              color: #fff;
            }
            &.titleRecognition {
              border: none;
              background-color: #11cc88;
              color: #fff;
            }
            &.equityInvestment {
              border: none;
              background-color: #11cc88;
              color: #fff;
            }
            &.taxAndFeeReductions {
              border: none;
              background-color: #e91f0a;
              color: #fff;
            }
            &.financialSupport {
              border: none;
              background-color: #6cc2ed;
              color: #fff;
            }
            &.others {
              border: none;
              background-color: rgba(0, 0, 0, 0.4);
              color: #fff;
            }
          }
        }
        & > div:last-child {
          display: flex;
          font-size: 14px;
          margin-top: 21px;
          span:first-child {
            width: 177px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #8d8d8d;
          }
          span:last-child {
            flex: 1;
            text-align: end;
            color: #8d8d8d;
          }
          span.red {
            color: red;
          }
        }
      }
    }
    .el-pagination {
      margin-top: 20px;
      text-align: center;
    }
    // .li_card :hover{
    //     background-color: red;
    //   }
  }
}
.active {
  background: #11cc88;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #156ED0;
}
.creditCode{
    display: flex;
    .div-code{
        margin-right: 5px;
        text-align:center;
        /deep/.el-input__inner{
          width: 40px;
          height: 30px;
          font-size: 12px;
        }
    }
  }
.bind-btn-box {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .bind-btn {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    border: none;
    width: 320px;
    height: 46px;
    background: #156ED0;
    border-radius: 5px;
    cursor: pointer;
  }
.goto-bind {
  margin-right: 10px;
  width: 120px;
  height: 42px;
  border: 1px solid #156ED0;
  border-radius: 5px;
  cursor: pointer;
  background: #fff;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #156ED0;
}
.hasBindEntity {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.information_color {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  opacity: 0.8;
}
.li_card:hover {
  cursor: pointer;
  border: 2px solid #b7cef5 !important;
}
.li_card {
  border: 1px solid #cccccc;
}
 /deep/.el-form-item{
    width: 60%;
  }
.form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  /deep/.el-input__inner {
    font-size: 16px;
    // width:150%;
    height: 50px;
    border: 1px solid #cccccc;
    border-radius: 5px;
  }
  .loading{
    height: 300px;
  }
</style>
